<template>
  <div>
    <LineChartGenerator
            :ref="'chart-'+code"
            :chart-options="chartOptions"
            :chart-data="chartData"
            :chart-id="chartId"
            :dataset-id-key="datasetIdKey"
            :plugins="plugins"
            :css-classes="cssClasses"
            :styles="styles"
            :width="width"
            :height="height"
            :key="chartData.datasets.data + loadDate"
    />
  </div>
</template>

<script>
  import { Line as LineChartGenerator } from 'vue-chartjs/legacy'
  import 'chartjs-adapter-moment';

  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    LineElement,
    TimeScale,
    LinearScale,
    CategoryScale,
    PointElement
  } from 'chart.js'

  ChartJS.register(
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    TimeScale,
    CategoryScale,
    PointElement
  )

  // const tempLevelData = [{measureDate: new Date("2024-06-05 18:00:00"), seaLevel: 52}, {measureDate: new Date("2024-06-06 18:01:00"), seaLevel: 44}, {measureDate: new Date("2024-06-07 18:11:00"), seaLevel: 70}];
  // const tempChangeData = [{measureDate: new Date("2024-06-05 18:00:00"), seaChangeSpeed: -12}, {measureDate: new Date("2024-06-05 23:01:00"), seaChangeSpeed: 24}, {measureDate: new Date("2024-06-07 18:11:00"), seaChangeSpeed: -124}];
  // const tempLevelData = [{x: new Date("2024-06-05 18:00:00"), y: 12}, {x: new Date("2024-06-05 18:01:00"), y: 24}, {x: new Date("2024-06-07 18:11:00"), y: 124}];
  // const tempChangeData = [{x: new Date("2024-06-05 10:00:00"), y: -12}, {x: new Date("2024-06-05 18:01:00"), y: 24}, {x: new Date("2024-06-07 09:11:00"), y: -124}];

  export default {
    name: 'TimeChart',
    components: {
      LineChartGenerator
    },
    props: {
      code: Number,
      chartSettings: Object,
      pointData: {
        type: Array,
        default: () => []
      },
      loadDate: {
        type: Date
      },
      waveDate: {
        type: Date
      },
      type: Number,
      chartId: {
        type: String,
        default: 'line-chart'
      },
      datasetIdKey: {
        type: String,
        default: 'label'
      },
      width: {
        type: Number,
        default: 400
      },
      height: {
        type: Number,
        default: 400
      },
      cssClasses: {
        default: '',
        type: String
      },
      styles: {
        type: Object,
        default: () => {}
      },
      plugins: {
        type: Array,
        default: () => []
      }
    },
    methods: {
      setupChart() {
        if (!this.chartData.datasets) return;
        let data, tideData
        let allValues = []
        if (this.type === 1) {
          data = this.pointData.map(pd => {
            return {x: new Date(pd.measureDate), y: pd.seaLevel}
          })

          tideData = this.pointData.map(pd => {
            return {x: new Date(pd.measureDate), y: pd.tide}
          })

           // data = tempLevelData.map(pd => {
           //  return {x: new Date(pd.measureDate), y: pd.seaLevel}
          // })
          this.chartData.datasets[0].label = 'Уровень моря'
          this.chartData.datasets[0].backgroundColor = this.chartSettings.seaLevel.color
          this.chartData.datasets[0].borderColor = [this.chartSettings.seaLevel.color]
          this.chartData.datasets[0].pointRadius = this.chartSettings.seaLevel.pointSize
          this.chartData.datasets[0].pointHoverRadius = this.chartSettings.seaLevel.pointSize
          this.chartData.datasets[0].borderWidth = this.chartSettings.seaLevel.lineSize
          this.chartData.datasets[0].data = data

          this.chartData.datasets[1].label = 'Прилив'
          this.chartData.datasets[1].backgroundColor = this.chartSettings.forecast.color
          this.chartData.datasets[1].borderColor = [this.chartSettings.forecast.color]
          this.chartData.datasets[1].pointRadius = this.chartSettings.forecast.pointSize
          this.chartData.datasets[1].pointHoverRadius = this.chartSettings.forecast.pointSize
          this.chartData.datasets[1].borderWidth = this.chartSettings.forecast.lineSize
          this.chartData.datasets[1].data = tideData
        } else {
          data = this.pointData.map(pd => {
            return {x: pd.measureDate, y: pd.seaLevelSpeed}
          })
          // data = tempChangeData.map(pd => {
          //   return {x: new Date(pd.measureDate), y: pd.seaChangeSpeed}
          // })
          this.chartData.datasets[0].label = 'Скорость изменений'
          this.chartData.datasets[0].backgroundColor = this.chartSettings.seaChangeSpeed.color
          this.chartData.datasets[0].borderColor = [this.chartSettings.seaChangeSpeed.color]
          this.chartData.datasets[0].pointRadius = this.chartSettings.seaChangeSpeed.pointSize
          this.chartData.datasets[0].pointHoverRadius = this.chartSettings.seaChangeSpeed.pointSize
          // this.chartData.datasets[0].borderWidth = this.chartSettings.seaChangeSpeed.lineSize
          this.chartData.datasets[0].data = data

          this.chartData.datasets[1].label = ''
          this.chartData.datasets[1].data = []
        }

        allValues = data.map(data => data.y)
        // if (tideData) allValues.push(...tideData)



        // console.log('allValues', allValues)
        console.log('check signal line')
        if (this.chartData.datasets[2] && this.waveDate) {
          console.log('this.waveDate', this.waveDate)
          if (!allValues.length) {
            this.waveDate.setHours(this.waveDate.getHours() - 3);
            this.chartData.datasets[2].data = [{x: this.waveDate, y: -100}, {x: this.waveDate, y: 100}]
          } else {
            this.chartData.datasets[2].data = [{x: this.waveDate, y: Math.min(...allValues)}, {
              x: this.waveDate,
              y: Math.max(...allValues)
            }]
          }
        } else {
          this.chartData.datasets[2].data = []
        }
        // console.log('new data1', this.chartData.datasets[0].data)
        // console.log('new data2', this.chartData.datasets[1].data)
        // console.log('y', this.$refs['chart-'+this.code])
      }
    },
    watch: {
      type() {
        this.setupChart()
      },
      chartSettings() {
        this.setupChart()
      },
      loadDate() {
        this.setupChart()
      }
    },
    data() {
      return {
        chartData: {
          datasets: [
            {
              label: 'Уровень моря', // "Уровень моря",
              backgroundColor: '#f87979',
              data: [],//tempLevelData,
              fill: true,
            },
            {
              label: 'Прилив', // "Уровень моря",
              backgroundColor: '#f87979',
              data: [],//tempLevelData,
              fill: true,
            },
            {
              // label: 'землетрясение',
              legend: { hidden: true },
              fill: true,
              backgroundColor: 'blue',
              data: [{x: new Date(), y: 0}, {x: new Date(), y: 70}],
              pointRadius: 0,
              borderWidth: 5,
              borderColor: "black",
            },
          ]
        },
        chartOptions: {
          // elements: {
          //   point: {
          //     radius: this.settings.pointSize,
          //     hoverRadius: this.settings.pointSize + 2, // ex.: to make it bigger when user hovers put larger number than radius.
          //     backgroundColor: this.settings.color
          //   },
          //   line: {
          //     borderWidth: this.settings.lineSize,
          //     backgroundColor: this.settings.color,
          //     borderColor: this.settings.color
          //   }
          // },
          tooltips: {
            callbacks: {
              label: function (tti, data) {
                // Here is the trick: the second argument has the dataset label
                return '{0}: {1}'.Format(data.datasets[tti.datasetIndex].label, tti.yLabel + '__ddd');
              }
            }
          },
          annotation: {
            annotations: [ {
              type: 'line',
              mode: 'horizontal',
              scaleID: 'x-axis-0',
              value: new Date(),
              borderColor: 'red',
              borderWidth: 2,
              label: {
                enabled: true,
                content: 'hello, world!!',
              }
            } ],
          },
          responsive: true,
          maintainAspectRatio: false,
          legend: { //for newer version
            onClick: null,
          },
          scales: {
            x: {

              gridLines: {
                display: true
              },
              ticks: {
                font: {size: 20}
              },
              type: 'time',
              timeZone: 'UTC',
              time: {
                timeZone: 'UTC+0',
                unit: 'minute',
                stepSize: 5,
                displayFormats: {
                  // minute: "MM-DD HH:mm"
                  minute: "HH:mm"
                },
                tooltipFormat:'YYYY-MM-DD HH:mm:ss', // <- HERE,
                // Установите время начала дня 0:00
                // min: '2024-09-23 00:00:00',
                // Установите время окончания дня 23:59
                // max: '2024-09-23 23:59:59'
              },

            },
            y: {
              title: {display: true, text: 'Уровень моря [см]', font: {size: 26}},
              ticks: {
                // fontSize: 40,
                font: {size: 30}
              },
            }
          },

          plugins: { //for older version
            tooltip: {
              titleFont: {
                size: 30
              },
              bodyFont: {
                size: 30
              },
              footerFont: {
                size: 20 // there is no footer by default
              },
              // callbacks: {
              //   label: function(tooltipItem) {
              //     // return "Локальное время " + tooltipItem.yLabel;
              //     // console.log(Object.keys(tooltipItem).map(key => key + '=' + tooltipItem[key] + Object.keys(tooltipItem[key]).join(';'))).join(', ')
              //     // return "Локальное время " + Object.keys(tooltipItem).map(key => key + '=' + tooltipItem[key]).join(', ')
              //     return tooltipItem.formattedValue
              //   },
              // }
              callbacks: {
                title: function(tooltipItem){
                  // return "Локальное время " + Object.keys(tooltipItem).map(key => key + '=' + tooltipItem[key]).join(', ')
                  // return "Локальное время " + Object.keys(tooltipItem[0]).map(key => key + '=' + tooltipItem[0][key]).join(', ') + tooltipItem[0].parsed
                  // return "Локальное время " + Object.keys(tooltipItem[0].parsed).map(key => key + '=' + tooltipItem[0].parsed[key]).join(', ')
                  // return "Локальное время " + Object.keys(tooltipItem[0].parsed).map(key => key + '=' + tooltipItem[0].parsed[key]).join(', ')
                  let date = new Date(tooltipItem[0].parsed.x)
                  let utcTitle = date.toISOString().substring(0, 19).replace('T',' ')
                  return "ВСВ " + utcTitle + ', локальное ' + (date.toLocaleDateString()) + ' ' +(date.toLocaleTimeString())
                }
              }
            },
            legend: {
              onClick: null,
              labels: {
                font: {size: 24},
                filter: function(legendItem, data) {
                  let label = data.datasets[legendItem.datasetIndex].label || '';
                  if (typeof(label) !== 'undefined') {
                    if (legendItem.datasetIndex >= 3){
                      return false;
                    }
                  }
                  return label;
                }
              }
            }
          }
        }
      }
    },
    async mounted() {
      this.setupChart()
    }
  }
</script>

<style scoped>
</style>